import React from 'react';
import Article from 'components/help/helpArticle';
import CopyCodeSection from 'components/help/installCodeInstruction';
import Link from 'components/ui/link';

const Content = () => {
  return (
    <div>
      Using LiveSession requires placing a simple JavaScript code into your website.
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        You need to force your visitors to use HTTPS to make our tracking code work. If you
        don&apos;t have an SSL, our tracking code won&apos;t work correctly.{' '}
        <Link
          href="https://share.hsforms.com/1WiHAXRr6S7O3PN6aFQLr9w2ymzp"
          rel="noopener noreferrer"
          target="_blank"
        >
          Contact us
        </Link>{' '}
        if you need help with setting up SSL.
      </div>
      <CopyCodeSection />
    </div>
  );
};

const related = [
  {
    link: '/help/get-started',
    title: 'Check other installation guides',
    description: 'See how to install LiveSession in several ways and get started.',
  },
  {
    link: '/help/how-to-check-if-my-tracking-script-works',
    title: 'How to check if my tracking script works',
    description: 'Check if your tracking code is installed correctly',
  },
];

export const frontmatter = {
  metaTitle: 'How to install LiveSession script on your website?',
  metaDescription: 'Check how can you install LiveSession on any website.',
  canonical: '/help/how-to-install-livesession-script/',
};

const Wrapped = Article(Content);
export default () => (
  <Wrapped related={related} section="Get Started" title={frontmatter.metaTitle} {...frontmatter} />
);
