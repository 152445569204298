import React from 'react';
import { SubTitle } from 'components/help/helpArticle';
import img1 from 'img/help/install-code/settings_menu.png';
import img2 from 'img/help/install-code/websites_menu.png';
import img3 from 'img/help/install-code/tracking_code_tab.png';
import img4 from 'img/help/install-code/copy_button.png';
import img5 from 'img/help/install-code/gtm.png';
import Code from 'components/help/codeSnippet';
import Image from '../helpImage';

export default () => (
  <>
    <SubTitle>Installing the code manually</SubTitle>
    <p>
      After logging in to LiveSession Application go to
      <strong> Settings</strong>:
    </p>
    <Image
      src={img1}
      alt="Install code instruction"
      title="Go to settings on LiveSession Application."
    />
    <p>
      Choose the website that you want to set up and click on the Tracking code. If you haven&apos;t
      added a website yet, please click on Add website button and type in the website’s address
      there:
    </p>

    <Image src={img2} lazy alt="Install code instruction" title="Choose a website" />
    <p>
      Open the <strong>Tracking code</strong> tab:
    </p>
    <Image src={img3} lazy alt="Install code instruction" title="Click Tracking code tab" />
    <p>
      And click on the button to <strong>Copy to clipboard</strong> button:
    </p>
    <Image src={img4} alt="Install code instruction" title="Click to copy to clipboard" />
    <p>
      Paste it into your website source code in the <Code inline>{'<head>'}</Code> section.
    </p>
    <SubTitle>Installing the code with Google Tag Manager</SubTitle>
    <p>
      If you want to install LiveSession tracking code using the Google Tag Manager, go to the
      “Google Tag Manager” tab (1), click the “Connect Google Tag Manager” button (2) and follow the
      on-screen instructions:
    </p>
    <Image src={img5} alt="Install code instruction" title="Install with Google Tag Manager" />
    <p>
      It&apos;s done! Now you can test if your code works correctly{' '}
      <a
        href="/help/how-to-check-if-my-tracking-script-works/"
        target="_blank"
        rel="noopener noreferrer"
      >
        using script debug mode
      </a>
      .
    </p>
  </>
);
